import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { fadeInUp, fadeIn } from "../animations/variants";
import { useInView } from "react-intersection-observer";

function IntersectionBox({ variants, children, threshold, className }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: threshold,
  });

  const [hasTriggered, setHasTriggered] = useState(false);

  useEffect(() => {
    if (inView) {
      setHasTriggered(true);
    }
  }, [inView]);

  return (
    <motion.span
      ref={ref}
      className={className}
      variants={variants}
      initial="hidden"
      exit="hidden"
      animate={inView || hasTriggered ? "show" : "hidden"}
    >
      {children}
    </motion.span>
  );
}

IntersectionBox.defaultProps = {
  variants: fadeInUp,
  threshold: 0.8,
  className: "",
};

export default IntersectionBox;
