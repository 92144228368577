import React from "react";
import ReactMarkdown from "react-markdown";
import SEO from "../components/seo";
import Layout from "../components/layout";
import readingTime from "reading-time";
import IntersectionBox from "../animations/IntersectionBox";
import {
  fadeInFromLeftWithDelay,
  fadeInFromRightWithDelay,
} from "../animations/variants";
import { navigate } from "gatsby";
import { format } from "date-fns";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

function renderBlock(block) {
  switch (block.__component) {
    case "blog.text":
      return (
        <div
          className="blog-section triggerAnimation animated"
          data-animate="fadeInUp"
        >
          <ReactMarkdown className="sec-desc">{block.text}</ReactMarkdown>
        </div>
      );
    case "blog.paragraph":
      return (
        <div
          className="blog-section triggerAnimation animated"
          data-animate="fadeInUp"
        >
          <h2 className="sec-head">{block.heading} </h2>
          <ReactMarkdown className="sec-desc">{block.text}</ReactMarkdown>
        </div>
      );
    case "blog.blog-image":
      return (
        <div className="blog-section sec-img ">
          <img
            className="triggerAnimation animated fadeInUp"
            src={block.blog_image?.url}
          />
        </div>
      );
  }
  return <></>;
}

function Blog({ pageContext }) {
  const { blog, prevBlog, nextBlog } = pageContext;

  const text = blog.blog_content
    .map((block) => {
      if (block.text) {
        return block.text;
      }
    })
    .join(" ");

  const rT = readingTime(text);
  const d = new Date(blog.createdAt);
  const formattedDate = format(d, "do LLLL yyyy");

  return (
    <Layout>
      <SEO
        title={blog.seo.title}
        description={blog.seo.meta_description}
        keywords={blog.seo.meta_keywords}
      />
      <div className="blog-page">
        <IntersectionBox variants={fadeInFromLeftWithDelay(0.2)}>
          <h1 className="blog-head">{blog.name}</h1>
        </IntersectionBox>

        <div className="row blog-intro">
          <IntersectionBox variants={fadeInFromLeftWithDelay(0.3)}>
            <div className="blog-details">
              <div className="row">
                <div className="author-img">
                  <img
                    src={blog.author_avatar?.url}
                    className="author-avatar"
                    alt={blog.author}
                  />
                </div>
                <div className="author-details">
                  <p className="auth-name"> {blog.author} </p>
                  <p className="blog-date">
                    {formattedDate} · {rT.text}
                  </p>
                </div>
              </div>

              <p className="intro-detail"> {blog.summary} </p>
            </div>
          </IntersectionBox>
          <IntersectionBox variants={fadeInFromRightWithDelay(0.3)}>
            <div className="blog-img">
              <img src={blog.banner_image?.url} alt={blog.name} />
            </div>
          </IntersectionBox>
        </div>
        <div className="content">
          {blog.blog_content.map((block, index) => renderBlock(block))}
        </div>
        <div className="blog-nav">
          {prevBlog && (
            <div
              className="prev-nav"
              onClick={() => {
                navigate(`/blog/${prevBlog.url}`);
              }}
            >
              <div className="prev-nav-button">
                <FaAngleLeft />
              </div>
              <div className="prev-blog">
                <div className="header">{prevBlog.name}</div>
                {/* <div className="desc">Prev Blog Desc</div> */}
              </div>
            </div>
          )}
          {nextBlog && (
            <div
              className="next-nav"
              onClick={() => {
                navigate(`/blog/${nextBlog.url}`);
              }}
            >
              <div className="next-blog">
                <div className="header">{nextBlog.name}</div>
                {/* <div className="desc">Next Blog Desc</div> */}
              </div>
              <div className="next-nav-button">
                <FaAngleRight />
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Blog;
